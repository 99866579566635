import React from "react";
import { FaDesktop, FaServer, FaFile } from "react-icons/fa";
import { ImMenu } from 'react-icons/im';
import counterLogo from '../../assets/counter.png';
import alluviumLogo from '../../assets/alluvium-logo.png';

import './sideBar.scss';

const SideBar = ({ showMobile, showMobileMenu, selectedOption, setSelectedOption, setTheme }) => {
    // const [selectedOption, setSelectedOption] = useState('jira-software');

    const handleSoftwareCount = () => {
        setSelectedOption('software');
        setTheme('software-theme');
        showMobileMenu(false)
    }

    const handleServiceDeskCount = () => {
        setSelectedOption('servicedesk');
        setTheme('sdc-theme');
        showMobileMenu(false)
    }

    const handleSchemeCount = () => {
        setSelectedOption('scheme');
        setTheme('scheme-theme');
        showMobileMenu(false)
    }

    const handleAttachments = () => {
        setSelectedOption('attachments');
        setTheme('scheme-theme');
        showMobileMenu(false)
    }

    return (
        <div className={`container ${showMobile ? 'active' : ''}`}>
            <div className="profile">
                <div className="logo2">
                    <img src={require("../../assets/Logo.svg")} alt="Cloud Counter" />
                    <h3>Cloud <br /> Counter</h3>
                </div>
                <div className="close-menu-btn" onClick={() => showMobileMenu(false)}>
                    <div className="bar bar-1"></div>
                    <div className="bar bar-3"></div>
                </div>
            </div>
            <nav className="menu">
                <ul className="links">
                    <li className={`nav-item ${selectedOption === 'software' ? "active" : ""}`} onClick={handleSoftwareCount}>
                        <FaDesktop />
                        <span>Jira Software</span>
                    </li>
                    <li className={`nav-item ${selectedOption === 'scheme' ? "active" : ""}`} onClick={handleSchemeCount}>
                        <ImMenu />
                        <span>Jira Schemes</span>
                    </li>
                    <li className={`nav-item ${selectedOption === 'servicedesk' ? "active" : ""}`} onClick={handleServiceDeskCount}>
                        <FaServer />
                        <span>Jira Service Management</span>
                    </li>
                    <li className={`nav-item ${selectedOption === 'attachments' ? "active" : ""}`} onClick={handleAttachments}>
                        <FaFile />
                        <span>Attachments</span>
                    </li>
                </ul>
            </nav>
            <div className="logos">
                <div>
                    <img src={counterLogo} alt="counter" />
                    <img src={alluviumLogo} alt="Alluvium" />
                </div>
            </div>
        </div>
    )
}

export default SideBar;