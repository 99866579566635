import React, { useState, useContext, useEffect, useCallback } from 'react';
import Axios from 'axios';
import ProgressBar from '@atlaskit/progress-bar';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import Banner from '@atlaskit/banner';
import JiraServiceDeskCounts from '../../components/jira-service-desk-counts/jira-service-desk-counts';
import JiraSoftwareCounts from '../../components/jira-software-counts/jira-software-counts';
import SchemeCounts from '../../components/scheme-counts/scheme-counts';
import Attachments from '../../components/attachments/attachments';
import { AppContext } from '../../store/index';
import './homepage.scss';
import { useToasts } from 'react-toast-notifications';

import SideBar from '../../components/sidebar/sidebar';
import Exports from '../../components/exports/exports.tsx';
import SaveTo from '../../components/save/saveTo';

const Homepage = () => {
    const [state, dispatch] = useContext(AppContext);
    const [selectedOption, setSelectedOption] = useState('software');
    const [theme, setTheme] = useState('software-theme');
    const { addToast } = useToasts();

    const [mobileMenu, showMobileMenu] = useState(false);
    const [optionsMenu, showOptionsMenu] = useState(false);

    const [progressValue, setProgressValue] = useState(0);

    const requestResources = (token) => {
        const resources = ['scheme', 'software', 'servicedesk']
        const requests = resources.map((resource) => {
            return Axios.post(`v1/jira/${resource}/`, { token: token })
                .then(res => {
                    const resourceData = res.data;
                    return {
                        resource,
                        response: resourceData
                    }
                }).catch(err => {
                    addToast(`Unable to get ${resource} data at the moment. Kindly try again by refreshing the page`, {appearance: 'error'});
                    console.log(err);
                });
        });
        Promise.all(requests).then(results => {
            results.forEach((result) => {
                if (result === undefined) return;
                const { previous, ...data } = result.response;
                let id = 1;
                dispatch({
                    type: `UPDATE_${result.resource.toUpperCase()}_DATA`,
                    payload: [
                        { data, date: new Date().toISOString(), id: id++ },
                        ...(previous.map(data => ({
                            ...data,
                            id: id++
                        }))),
                    ]
                });
            });
            setProgressValue(0);
        });
    }

    useEffect(() => {
        const location = window.location;
        // console.log(location);
        const searchObj = new URLSearchParams(location.search.substr(1));
        // console.log(searchObj);
        const jwt = searchObj.get("jwt");
        const lic = searchObj.get("lic");
        if (!lic || (lic.toLowerCase() !== "active")) {
            addToast(`Unable to verify your license. Kindly reach out to your site admin`, { appearance: 'error' });
        } else {
            dispatch({ type: 'UPDATE_JWT', payload: jwt })
            requestResources(jwt);
        }
    }, [])

    const getViewData = useCallback(() => {
        switch (selectedOption) {
            case "software":
                return { data: state.software_counts, title: "Jira Software" };
            case "servicedesk":
                return { data: state.service_desk_counts, title: "Jira Service Management" };
            case "scheme":
                return { data: state.scheme_counts, title: "Scheme" };
            case "attachments":
                return;
            default:
                return;
        }
    }, [selectedOption, state]);

    // const handleSoftwareCount = () => {
    //     setSelectedOption('software');
    //     setTheme('software-theme');
    // }

    // const handleServiceDeskCount = () => {
    //     setSelectedOption('servicedesk');
    //     setTheme('sdc-theme');
    // }

    // const handleSchemeCount = () => {
    //     setSelectedOption('scheme');
    //     setTheme('scheme-theme');
    // }

    // const handleAttachments = () => {
    //     setSelectedOption('attachments');
    //     setTheme('scheme-theme');
    // }

    const loadingScreen = () => {
        setTimeout(() => {
            setProgressValue(1.0)
        }, 5000)
        if (progressValue !== 1.0) {
            return (
                <div className={`loading-screen ${progressValue === 1.0 ? "hide-loading-screen" : ""}`}>
                    <div className="loader">
                        <img src={require('../../assets/loading.gif')} alt="loader" />
                        <ProgressBar value={progressValue} isIndeterminate={true} />
                    </div>

                </div>
            )
        }
    }

    return (
        <div className="homepage">
            {loadingScreen()}
            {
                progressValue === 1.0

                    ?

                    <div className='main-view'>
                        <SideBar showMobile={mobileMenu} showMobileMenu={showMobileMenu} selectedOption={selectedOption} setSelectedOption={setSelectedOption} setTheme={setTheme} />
                        <div className='content-view'>
                            <div className='black-bg'></div>
                            <div className="result-page">
                                <div className='data-section bg-white'>
                                    <div className={`banner-div ${state.jwt === null ? "" : "hide"}`}>
                                        <Banner
                                            appearance="warning"
                                            icon={<WarningIcon label="Warning icon" secondaryColor="inherit" />}
                                        >
                                            JWT is missing
                                        </Banner>
                                    </div>
                                    <div className={`text-menu ${mobileMenu ? 'hide-btn' : ''}`} onClick={() => showMobileMenu(true)} >
                                        <p>Menu</p>
                                    </div>
                                    <div className={`text-menu-2 ${optionsMenu ? 'hide-btn' : ''}`} onClick={() => showOptionsMenu(true)} >
                                        <p>Options</p>
                                    </div>
                                    <main className={`${theme}`}>
                                        <div className="output">

                                            {
                                                selectedOption === 'servicedesk' ? <JiraServiceDeskCounts /> : null
                                            }

                                            {
                                                selectedOption === 'software' ? <JiraSoftwareCounts /> : null
                                            }

                                            {
                                                selectedOption === 'scheme' ? <SchemeCounts /> : null
                                            }

                                            {
                                                selectedOption === 'attachments' ? <Attachments JWT={state.jwt} /> : null
                                            }
                                        </div>
                                    </main>
                                </div>
                                <div className={`bg-white py-3 pt-5 px-0 px-md-4 right-end ${optionsMenu ? 'active' : ''}`}>
                                    {optionsMenu && <div className="close-menu-btn" onClick={() => showOptionsMenu(false)}>
                                        <div className="bar bar-1"></div>
                                        <div className="bar bar-3"></div>
                                    </div>}
                                    <div className='mb-5'>
                                        <h2 className='mb-4'>Export Report</h2>
                                        <Exports info={getViewData()} />
                                    </div>
                                    {/* <div className='mb-5'>
                                        <h2 className='mb-4'>Save To</h2>
                                        <SaveTo />
                                    </div>
                                    <div>
                                        <h2 className='mb-4'>Save To</h2>
                                        <SaveTo />
                                    </div> */}
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    <div>

                    </div>
            }

        </div>
    )
}

export default Homepage;