import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Homepage from './pages/homepage/homepage';
import HomepageConfluence from './pages/homepage-confluence/homepage';
import LandingPage from './pages/landing-page/landing-page';
import Attachments from './components/attachments/attachments';
import ContextProvider from './store/index';
import { ToastProvider } from 'react-toast-notifications';

import './App.css';

function App() {
  return (
    <ToastProvider>
      <ContextProvider>
        <Router>
          <Switch>
            <Route path='/home' component={Homepage} />
            <Route path='/confluence-home' component={HomepageConfluence} />
            <Route path='/landing' component={LandingPage} />
            <Route exact path='/'>
              <Redirect to='/landing' />
            </Route>
            <Route path="/at" component={Attachments} />
          </Switch>
        </Router>
      </ContextProvider>
    </ToastProvider>
  );
}

export default App;
