import moment from "moment";
import { jsPDF, CellConfig, TableConfig } from "jspdf";
// import { getTokenGDrive, UtilsGDrive } from "utils-google-drive";
import {rename} from "fs";
import path from "path";

export const titleCase = (str: string) => {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

// returns an array with the object keys sorted in alphabetical order
export const sortObjectKeys = (obj: { [key: string]: any }) => {
  const list = [];
  for (let key in obj) {
    list.push([key, obj[key]]);
  }
  return list.sort((a, b) => {
    if (a[0] < b[0]) {
      return -1;
    } else if (b[0] > a[0]) {
      return 1;
    } else {
      return 0;
    }
  });
};

export const generateGraphData = (
  setGraphData: Function,
  sortedList: any[],
  oldData: any,
  length: number
) => {
  const data: string[] = [];
  sortedList.forEach((item) => {
    data.push(oldData?.data[item[0]]);
  });
  const dataSet = {
    label: formatDate(oldData?.date),
    data,
    backgroundColor: `rgb(${getRandomNumber(0, 255)}, ${getRandomNumber(
      0,
      255
    )}, ${getRandomNumber(0, 255)})`,
    stack: `Stack ${length}`,
  };
  setGraphData((prev: { datasets: any }) => ({
    ...prev,
    datasets: prev?.datasets ? [...prev?.datasets, dataSet] : [dataSet],
  }));
  // console.log(dataSet);
};

export const getRandomNumber = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};

export const exportAsPdfFile = (
  data: { [key: string]: string }[],
  title: string | string[],
  headers: { header: string; key: string; width: number }[],
  layout: Orientation = "landscape",
  image = null
) => {
  const config = new Config();
  config.autoSize = false;
  config.headerBackgroundColor = "#009bc0";
  config.headerTextColor = "#fff";
  config.fontSize = 14;

  const doc = new jsPDF({ orientation: layout });
  doc.text(title, layout === "landscape" ? 130 : 65, 10);
  const mainHeader = createHeaders(headers);

  if (image) {
    doc.addImage(image, "PNG", 70, 20, 70, 70);
    doc.table(10, 100, data, mainHeader, config);
  } else {
    // debugger;
    doc.table(10, 30, data, mainHeader, config);
  }
// console.log(doc.output('datauristring'));
  doc.save(title + ".pdf");
  const sourcePath = title + ".pdf";
  // getTokenGDrive({ pathCredentials: 'path/to/credentials.json' });

  // const destinationPath = '/frontend/src/assets/pdfFiles';

  // rename(sourcePath, path.join(destinationPath, path.basename(sourcePath)), (err) => {
  //   if (err) throw err;
  //   console.log('File saved successfully');
  // });
};

const createHeaders = (keys: any[]) => {
  let result: CellConfig[] = [];
  for (let i = 0; i < keys.length; i++) {
    result.push({
      // header: keys[i].header,
      name: keys[i].key,
      prompt: keys[i].header,
      align: "center",
      width: keys[i].width?.toString(),
      padding: 0,
    });
  }
  return result;
};

export const checkForValue = (
  value: any,
  returnType: "string" | "number" = "string"
) => {
  let output = value;
  if (value === null || value === "" || !value || value === "null") {
    output = returnType === "number" ? 0 : "N/A";
    return output;
  }
  return output;
};

export const formatDate = (
  date: Date | string,
  dateFormat?: string
): string => {
  return moment(date).format(dateFormat ? dateFormat : "LL LT");
};

export const formatDataForExport = (request: { data : {[key: string]: any}, date: string | Date }[]) => {
  // return request.map((req, index) => {
  //   let excelData: { [key: string]: any } = {};
  //   const sortedList = sortObjectKeys(req?.data);
  //   excelData.serial = "" + (index + 1);
  //   sortedList.forEach((row) => {
  //     excelData[row[0]] =
  //       typeof row[1] === "string" ? titleCase(row[1]) : row[1].toString();
  //   });
  //   excelData["date"] = req?.date ? formatDate(req?.date) : "N/A";
  //   return excelData;
  // });
  let output: { [key: string]: any }[] = [];
  const sortedList = sortObjectKeys(request[0]?.data);
  sortedList.forEach((row, index) => {
    if (row[0] !== 'date') {
      let pdfData: { [key: string]: any } = {
        serial: (index + 1) + '',
        resource: titleCase(row[0]),
      };
      request.forEach((req, i) => {
        let dateString = formatDate(req?.date);
        // pdfData[dateString + ` (${index})`] = row[0];
        pdfData[dateString + ` (${i})`] = typeof req?.data[row[0]] === "string" ? titleCase(req?.data[row[0]]) : req?.data[row[0]].toString();
      });
      output.push(pdfData);
    }
  });
  return output;
};

export class Config implements TableConfig {
  autoSize: boolean = true;
  headerTextColor: string = "#fff";
  fontSize: number = 10;
  headerBackgroundColor: any;
  top: any;
}

export type Orientation = "landscape" | "portrait";

export const generatePreliminaryHeaders = (wholeData: {data: {[key: string]: any}, date: string | Date }[]) => {
  const output: {
    header: string,
    key: string,
    width: number
  }[] = [{
    header: "SN",
    key: "serial",
    width: 30,
  },
  {
    header: "Resources",
    key: "resource",
    width: 80,
  }];
  wholeData.forEach((row, i) => {
    let day = formatDate(row?.date);
    output.push({
      header: titleCase(day),
      key: day + ` (${i})`,
      width: 65
    });
  });
  return output;
}

// export const serviceDeskCountHeader = [
//   {
//     header: "SN",
//     key: "serial",
//     width: 100,
//   },
//   {
//     header: "Customer Requests",
//     key: "customer requests",
//     width: 120,
//   },
//   {
//     header: "Organizations",
//     key: "organizations",
//     width: 100,
//   },
//   {
//     header: "Request Types",
//     key: "request types",
//     width: 200,
//   },
//   {
//     header: "Service Desks",
//     key: "service desks",
//     width: 200,
//   },
//   {
//     header: "Date",
//     key: "date",
//     width: 100,
//   }
// ];
