import React, { useState, useEffect, useContext } from 'react';
import TableTree from '@atlaskit/table-tree';
import {titleCase, sortObjectKeys} from "../../utils.ts";
import { AppContext } from '../../store/index';
import './confluence-resources.scss';

const ConfluenceResources =(props)=> {
    const [ state,dispatch ] = useContext(AppContext);
    const [ confluenceCountItems, setConfluenceCountItems ] = useState([]);

    useEffect(() => {
        const {previous, ...data} = state.confluence_counts;
        const sortedList = sortObjectKeys(data);
        const items = sortedList.map((row, id) => {
          return {
            id,
            content: {
              title: titleCase(row[0]),
              description: row[1],
            },
            hasChildren: false,
            children: []
          }
        });
        setConfluenceCountItems(items);
      }, [state.confluence_counts]);

    const Title = props => <span>{props.title}</span>;
    const Description = props => <span>{props.description}</span>;
    dispatch({ type:null, payload:null });

    return(
        <div className="jira-service-desk-counts-body">
            <TableTree
                columns={[Title, Description]}
                headers={['Resource', 'Count']}
                columnWidths={['300px', '300px']}
                items={confluenceCountItems}
            />
        </div>
    )
}


export default ConfluenceResources