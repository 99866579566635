import React, { useEffect, useState, useContext, useMemo, useCallback } from "react";
import { AppContext } from "../../store/index";
import { titleCase, sortObjectKeys, generateGraphData, formatDate } from "../../utils.ts";
import "./scheme-counts.scss";
import Table from "../Table/table";
import PreviousCounts from "../previous-counts/previous-counts";
import BarChartView from "../bar-chart/bar-chart.tsx";

const SchemeCounts = (props) => {
  const [state, dispatch] = useContext(AppContext);
  const [schemeCountItems, setschemeCountItems] = useState([]);
  const [graphData, setGraphData] = useState({});
  const [previousData, setPreviousData] = useState([]);
  const [currentViewId, setCurrentViewId] = useState(1);
  const sortedList = useMemo(() => sortObjectKeys(state.scheme_counts[0]?.data), [state?.softwareCountItems]);
  const graphDataLabels = useMemo(() => sortedList.map(row => titleCase(row[0])), [sortedList]);
  const date = useMemo(() => formatDate(state?.scheme_counts?.filter(info => info?.id == currentViewId)[0]?.date, "LL"), [currentViewId, state.scheme_counts]);

  useEffect(() => {
    if (state?.scheme_counts?.length > 0) {
      setPreviousData(state.scheme_counts.slice(1));

      setGraphData((prev) => ({
        ...prev,
        labels: graphDataLabels,
      }));

      state.scheme_counts.forEach((item, index) => {
        generateGraphData(setGraphData, sortedList, item, index + 1);
      });
    }
  }, [state.scheme_counts]);

  useEffect(() => {
    dispatch({ type: null, payload: null });
  }, [dispatch]);

  useEffect(() => {
    updateDataTable();
  }, [currentViewId]);

  const updateDataTable = useCallback(() => {
    const parsedData = state?.scheme_counts?.filter(info => info?.id === currentViewId);
    if (parsedData?.length > 0) {
      const items = sortedList.map((row, id) => {
        const title = titleCase(row[0]);
        graphDataLabels.push(title);
        return {
          id,
          title,
          description: parsedData[0]?.data[row[0]],
        };
      });
      setschemeCountItems(items);
    }

  }, [currentViewId, state.scheme_counts, setschemeCountItems]);

  return (
    <div className="scheme-counts-body">
      <div className="mb-5">
        <h1>Jira Scheme Counts</h1>
        <p>{date}</p>
      </div>
      <div
        className="container-fluid text-center mt-3 mb-5"
      >
        {Object.keys(graphData).length && <BarChartView data={graphData} />}
      </div>
      <div className="mt-5">
        <Table data={schemeCountItems} />
        {currentViewId > 1 && <div className="d-flex justify-content-end mt-5">
          <button className={`btn btn-primary`} onClick={() => setCurrentViewId(1)}>Reset Table Data</button>
        </div>}
      </div>
      <div className="mt-5">
        <PreviousCounts history={previousData} setCurrentViewId={setCurrentViewId} currentViewId={currentViewId} />
      </div>
    </div>
  );
};

export default SchemeCounts;
