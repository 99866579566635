import React from 'react';
import { Link } from 'react-router-dom';
import './landing-page.scss';

const LandingPage =()=>{

    return(
        <div className="landing-page">
            <div className="get-started-logo">
            <svg width="122" height="70" viewBox="0 0 122 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                    <path d="M0.0733459 55.9911C0.0595377 42.3826 -0.0923524 28.774 0.0825514 15.1654C0.183812 6.99662 7.72769 0.0269452 16.0356 0.0131621C36.0805 -0.0144042 56.1254 0.00397331 76.1703 0.00397331C85.8407 0.00397331 95.511 -0.000621068 105.177 0.00397331C114.912 0.0085677 122.004 7.06554 122 16.7918C121.995 29.8583 121.967 42.9247 121.949 55.9957C121.866 56.1887 121.737 56.3724 121.701 56.5746C120.26 64.4586 113.673 69.9902 105.674 69.9948C75.8435 69.9994 46.0132 70.004 16.1829 69.9902C11.9254 69.9902 8.27081 68.4373 5.14095 65.5658C2.31488 62.9654 0.901838 59.639 0.0733459 55.9911ZM61.0043 66.0804C75.7837 66.0804 90.563 66.085 105.342 66.0804C112.693 66.0758 118.069 60.7371 118.074 53.432C118.083 41.1742 118.083 28.9164 118.074 16.6586C118.069 9.23409 112.73 3.91379 105.273 3.91379C75.7699 3.9092 46.2664 3.9092 16.7629 3.91379C9.29722 3.91379 3.95345 9.2249 3.94885 16.6448C3.93964 28.8475 4.10534 41.0548 3.88441 53.2529C3.76013 60.2179 9.81273 66.2458 16.8319 66.1447C31.5514 65.9334 46.2802 66.0804 61.0043 66.0804Z" fill="#17164A"/>
                    <path d="M26.0607 15.3039C24.2748 15.3039 22.4844 15.3315 20.6985 15.2947C18.908 15.2579 17.6561 14.0358 17.6469 12.4002C17.6377 10.76 18.885 9.47821 20.6571 9.45984C24.2886 9.41849 27.9156 9.42308 31.5471 9.45984C33.333 9.47821 34.5849 10.7187 34.5988 12.3589C34.6126 13.9991 33.3606 15.2579 31.5886 15.2993C29.7475 15.3361 27.9018 15.3039 26.0607 15.3039Z" fill="#0FA0C5"/>
                    <path d="M60.7423 15.3039C58.9518 15.3039 57.1567 15.3315 55.3663 15.2947C53.4838 15.2579 52.1628 13.9853 52.2042 12.3129C52.2456 10.6957 53.516 9.47362 55.3294 9.45524C58.8597 9.41848 62.3854 9.42308 65.9157 9.45064C67.8489 9.46902 69.1422 10.6728 69.1561 12.3589C69.1699 14.0358 67.8535 15.2625 65.9479 15.2947C64.2173 15.3269 62.4821 15.3039 60.7423 15.3039Z" fill="#204BA0"/>
                    <path d="M95.1753 15.3039C93.44 15.3039 91.7048 15.3177 89.965 15.2993C88.0687 15.2763 86.7707 14.0772 86.7753 12.3819C86.7753 10.6773 88.064 9.46443 89.9604 9.45064C93.4861 9.42767 97.0118 9.42767 100.542 9.45064C102.443 9.46443 103.727 10.6727 103.732 12.3773C103.736 14.0726 102.443 15.2763 100.547 15.2993C98.7562 15.3223 96.9657 15.3039 95.1753 15.3039Z" fill="#E62126"/>
                    <path d="M17.6423 23.4911C17.6423 25.2737 17.6699 27.0609 17.6331 28.8435C17.5962 30.6307 16.3811 31.8804 14.738 31.8988C13.0902 31.9172 11.8014 30.6721 11.783 28.9079C11.7462 25.2829 11.7508 21.6625 11.783 18.0375C11.7968 16.2549 13.0349 14.9961 14.6735 14.9777C16.3167 14.9593 17.5916 16.209 17.6331 17.9686C17.6745 19.8156 17.6423 21.6533 17.6423 23.4911Z" fill="#0FA0C5"/>
                    <path d="M40.4671 23.4865C40.4671 25.2737 40.4902 27.0609 40.4625 28.8435C40.4303 30.6216 39.2198 31.8804 37.5766 31.9034C35.9381 31.9264 34.6263 30.6767 34.6079 28.9262C34.571 25.2461 34.5664 21.566 34.6125 17.8859C34.6355 16.2274 35.9243 15.0053 37.4938 14.9823C39.1278 14.9593 40.4211 16.2182 40.4579 17.9686C40.4994 19.8064 40.4671 21.6441 40.4671 23.4865Z" fill="#17164A"/>
                    <path d="M46.3356 23.4773C46.3356 21.6901 46.308 19.8983 46.3448 18.1111C46.3771 16.3055 47.5738 15.0328 49.1985 14.9869C50.8785 14.9409 52.1765 16.2503 52.1949 18.134C52.2317 21.7085 52.2363 25.2875 52.1949 28.8619C52.1765 30.6399 50.8785 31.9126 49.2584 31.9034C47.6244 31.8942 46.3863 30.6262 46.3448 28.8389C46.308 27.0517 46.3356 25.2645 46.3356 23.4773Z" fill="#204BA0"/>
                    <path d="M75.0337 23.4957C75.0337 25.2829 75.0567 27.0701 75.0245 28.8527C74.9923 30.6262 73.7726 31.885 72.1294 31.9034C70.4954 31.9218 69.1883 30.6675 69.1698 28.917C69.133 25.2921 69.1376 21.6625 69.1698 18.0375C69.1883 16.2595 70.431 15.0007 72.065 14.9823C73.7542 14.9639 75.0015 16.2687 75.0245 18.1386C75.0521 19.9212 75.0337 21.7085 75.0337 23.4957Z" fill="#17164A"/>
                    <path d="M86.7753 23.4773C86.7753 25.2645 86.7983 27.0517 86.7707 28.8389C86.7385 30.617 85.528 31.8758 83.8894 31.9034C82.1818 31.931 80.9252 30.6445 80.9114 28.7746C80.8884 25.2002 80.8884 21.6258 80.9114 18.0513C80.9252 16.2733 82.1496 15.0052 83.7881 14.9777C85.4865 14.9455 86.7431 16.2411 86.7707 18.1156C86.7937 19.9029 86.7753 21.6901 86.7753 23.4773Z" fill="#E62126"/>
                    <path d="M109.6 23.6059C109.6 25.338 109.623 27.0701 109.596 28.8022C109.563 30.6629 108.293 31.9493 106.59 31.908C104.961 31.8666 103.75 30.5986 103.736 28.8114C103.709 25.2415 103.709 21.6671 103.736 18.0973C103.75 16.2365 105.053 14.9409 106.742 14.9869C108.376 15.0328 109.563 16.3009 109.591 18.0881C109.623 19.9258 109.6 21.7636 109.6 23.6059Z" fill="#17164A"/>
                    <path d="M26.1205 37.7658C24.3301 37.7658 22.5396 37.7888 20.7538 37.7612C18.9541 37.7291 17.6837 36.5483 17.6423 34.9265C17.5963 33.2266 18.885 31.9355 20.7492 31.9172C24.3255 31.885 27.9064 31.885 31.4827 31.9172C33.3514 31.9355 34.6402 33.222 34.5942 34.9219C34.5527 36.5483 33.287 37.7291 31.4827 37.7612C29.7015 37.7888 27.911 37.7658 26.1205 37.7658Z" fill="#17164A"/>
                    <path d="M60.5305 37.7658C58.7953 37.7658 57.0601 37.7888 55.3249 37.7612C53.5252 37.7291 52.2548 36.5529 52.2042 34.9311C52.1536 33.2312 53.4423 31.9355 55.3064 31.9172C58.8828 31.885 62.4637 31.885 66.04 31.9172C67.9133 31.931 69.1975 33.2174 69.1607 34.9127C69.1238 36.5391 67.8581 37.7291 66.0584 37.7612C64.2173 37.7888 62.3762 37.7658 60.5305 37.7658Z" fill="#17164A"/>
                    <path d="M95.1661 37.7658C93.4309 37.7658 91.6956 37.7842 89.9604 37.7612C88.0641 37.7337 86.7661 36.5253 86.7753 34.8346C86.7799 33.1393 88.0733 31.9264 89.9696 31.9126C93.4953 31.8896 97.0164 31.8896 100.542 31.9126C102.438 31.9264 103.727 33.1485 103.727 34.8438C103.727 36.5345 102.429 37.7383 100.528 37.7612C98.7424 37.7842 96.9566 37.7658 95.1661 37.7658Z" fill="#17164A"/>
                    <path d="M11.7738 46.2287C11.7738 44.4461 11.7508 42.6588 11.7784 40.8762C11.806 39.0798 12.9935 37.8118 14.6229 37.7658C16.3213 37.7199 17.6192 39.0063 17.6331 40.8716C17.6653 44.4415 17.6653 48.0113 17.6331 51.5765C17.6192 53.4373 16.3259 54.7329 14.6229 54.6823C12.9935 54.6364 11.806 53.3729 11.7738 51.5765C11.7508 49.7985 11.7738 48.0159 11.7738 46.2287Z" fill="#0FA0C5"/>
                    <path d="M40.4672 46.316C40.4672 48.048 40.4856 49.7801 40.4626 51.5122C40.4396 53.3867 39.2152 54.6961 37.526 54.6915C35.8368 54.6869 34.6125 53.3821 34.5987 51.4984C34.5757 47.9791 34.5757 44.4644 34.5987 40.9451C34.6125 39.066 35.8506 37.7612 37.5352 37.7658C39.2244 37.7704 40.4349 39.0752 40.458 40.9589C40.4856 42.7461 40.4672 44.5288 40.4672 46.316Z" fill="#17164A"/>
                    <path d="M52.2088 46.4216C52.2088 48.1032 52.2318 49.7801 52.2042 51.4617C52.172 53.3637 50.9523 54.6823 49.2815 54.6869C47.5969 54.6915 46.3588 53.3775 46.345 51.4801C46.3219 47.9562 46.3173 44.4369 46.345 40.913C46.3634 39.0477 47.6705 37.7153 49.3413 37.7658C50.9615 37.8118 52.1628 39.0844 52.1996 40.8946C52.2364 42.7369 52.2088 44.5793 52.2088 46.4216Z" fill="#204BA0"/>
                    <path d="M75.0338 46.3389C75.0338 48.071 75.0522 49.8031 75.0292 51.5352C75.0062 53.3959 73.7542 54.7099 72.0742 54.6915C70.385 54.6731 69.1837 53.3683 69.1699 51.4755C69.1515 47.9562 69.1469 44.4415 69.1699 40.9222C69.1837 39.0522 70.4448 37.7474 72.1294 37.7658C73.8187 37.7842 75.0062 39.0844 75.0292 40.9819C75.0522 42.7691 75.0338 44.5517 75.0338 46.3389Z" fill="#17164A"/>
                    <path d="M80.9022 46.0633C80.9022 44.3312 80.8838 42.5991 80.9068 40.8624C80.9345 39.0798 82.122 37.8072 83.7559 37.7612C85.4543 37.7153 86.7523 39.0109 86.7661 40.867C86.7937 44.4415 86.7937 48.0159 86.7661 51.5903C86.7523 53.3775 85.5464 54.6502 83.917 54.6915C82.2094 54.7329 80.9345 53.4419 80.9114 51.5903C80.8838 49.748 80.9022 47.9056 80.9022 46.0633Z" fill="#E62126"/>
                    <path d="M109.6 46.2287C109.6 48.0159 109.623 49.8031 109.596 51.5857C109.568 53.3683 108.376 54.641 106.747 54.6869C105.057 54.7329 103.75 53.4327 103.736 51.5811C103.709 48.0113 103.709 44.4369 103.736 40.867C103.75 39.0155 105.062 37.7153 106.747 37.7612C108.371 37.8072 109.563 39.0844 109.591 40.867C109.623 42.6588 109.6 44.4461 109.6 46.2287Z" fill="#17164A"/>
                    <path d="M26.2356 60.5494C24.3945 60.5494 22.5488 60.5769 20.7077 60.5402C18.9126 60.5034 17.6607 59.2905 17.6423 57.6549C17.6239 56.0101 18.8712 54.7237 20.6387 54.7053C24.2702 54.664 27.9018 54.6685 31.5287 54.7053C33.3238 54.7237 34.5711 55.9504 34.5896 57.5906C34.608 59.2951 33.3284 60.5172 31.4321 60.5448C29.7014 60.5723 27.9708 60.5494 26.2356 60.5494Z" fill="#0FA0C5"/>
                    <path d="M60.7238 60.5494C58.9333 60.5494 57.1383 60.5769 55.3478 60.5402C53.5343 60.5034 52.2548 59.3135 52.2133 57.6963C52.1673 56.0101 53.4745 54.7145 55.3616 54.6961C58.9471 54.664 62.5281 54.6594 66.1136 54.7007C67.904 54.7237 69.1698 55.9963 69.1698 57.6227C69.1698 59.2446 67.8994 60.4942 66.1044 60.5356C64.3047 60.5861 62.5142 60.5494 60.7238 60.5494Z" fill="#204BA0"/>
                    <path d="M95.3869 54.6915C97.1222 54.6915 98.862 54.6685 100.597 54.6961C102.471 54.7237 103.759 55.9734 103.732 57.6779C103.704 59.3135 102.448 60.531 100.652 60.5448C97.0715 60.5723 93.4906 60.5723 89.9051 60.5448C88.0318 60.531 86.7384 59.2675 86.7706 57.5722C86.8028 55.9274 88.0548 54.7329 89.8498 54.7007C91.6955 54.664 93.5412 54.6915 95.3869 54.6915Z" fill="#E62126"/>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="122" height="70" fill="white"/>
                    </clipPath>
                </defs>
            </svg>

                <p>Cloud <br/> Counter</p>
            </div>
            <Link to='/home' className="get-started-btn">Get Started</Link>
        </div>
    )
}

export default LandingPage;