import React, { useState, useContext,useEffect } from 'react';
import Axios from 'axios';
import ProgressBar from '@atlaskit/progress-bar';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import Banner from '@atlaskit/banner';
import ConfluenceResources from '../../components/confluence-resources/confluence-resources';
import { AppContext } from '../../store/index';
import {useToasts} from 'react-toast-notifications';
// import './homepage.scss';

const Homepage =()=>{
    const [ state,dispatch ] = useContext(AppContext);
    // const [ theme, setTheme ] = useState('software-theme');
    const { addToast } = useToasts();
    const theme = "software-theme"

    const [ progressValue, setProgressValue ] = useState(0);

    const requestResources = (token) =>{
        const resources = ["resource"]
        const requests = resources.map((resource) => {
            return Axios.post(`v1/confluence/${resource}/`, { token: token })
                .then(res => {
                    const resourceData = res.data;
                    return {
                        resource,
                        response: resourceData
                    }
                }).catch(err => {
                    addToast(`Unable to get ${resource} data at the moment. Kindly try again by refreshing the page`, {appearance: 'error'});
                    console.log(err);
                });
        });
        Promise.all(requests).then(results => {
            results.forEach((result) => {
                if (result === undefined) return;
                dispatch({
                    type: `UPDATE_${result.resource.toUpperCase()}_DATA`,
                    payload: result.response
                });
            });
            setProgressValue(0);
        });
    }

    useEffect(() => {
        const location = window.location;
        const searchObj = new URLSearchParams(location.search.substr(1));
        const jwt = searchObj.get("jwt");
        const lic = searchObj.get("lic");
        if (!lic || (lic.toLowerCase() !== "active")) {
            addToast(`Unable to verify your license. Kindly reach out to your site admin`, { appearance: 'error' });
        } else {
            dispatch({ type: 'UPDATE_JWT', payload: jwt })
            requestResources(jwt);
        }
        // eslint-disable-next-line
    }, [])

    const loadingScreen = () => {
        setTimeout(()=>{
            setProgressValue(1.0)
        },5000)
        if (progressValue !== 1.0) {
            return (
                <div className={`loading-screen ${ progressValue === 1.0 ? "hide-loading-screen":""}`}>
                    <div className="loader">
                        <img src={ require('../../assets/loading.gif') } alt="loader" />
                        <ProgressBar value={ progressValue } isIndeterminate={ true } />
                    </div>
                </div>
            )
        }
    }

    return(
        <div className="homepage">
            { loadingScreen() }
            {
                progressValue === 1.0

                ?

                <div className="result-page">
                    <div className={`banner-div ${state.jwt === null ? "" : "hide"}`}>
                        <Banner
                        appearance="warning"
                        icon={<WarningIcon label="Warning icon" secondaryColor="inherit" />}
                        >
                        JWT is missing
                        </Banner>
                    </div>
                    <main className={`${theme}`}>
                        <div className="desktop-sidemenu">
                            <div className="logo">
                                <img src={require("../../assets/Logo.svg")} alt="Cloud Counter"/>
                                <h3>Cloud <br/> Counter</h3>
                            </div>
                        </div>
                        <div className="output">
                            <ConfluenceResources/>
                        </div>
                    </main>
                </div>
                :
                <div>

                </div>
            }

        </div>
    )
}

export default Homepage;