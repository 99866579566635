import React, {useEffect, useState} from "react";
import { FaCircle } from "react-icons/fa";
import moment from "moment";
import styles from "./previous-counts.module.scss";

const PreviousCounts = ({ history, setCurrentViewId, currentViewId }) => {
    const [historyData, setHistoryData] = useState([]);

    useEffect(() => {
        if(history?.length > 0){
            const data = history.map((item) => {
                let day = moment(item.date).format("LL");
                let time = moment(item.date).format("LT");
                return { date: item.date, day, time, id: item?.id };
            });
            setHistoryData(data);
        }
    }, [history]);

  return (
    <>
      <div className={`container-fluid mt-3 ${styles.historyCover}`}>
        <h4>Previous count</h4>
        <hr />
        <div className={styles.history}>
          {historyData?.map((item) => (
            <div key={item?.date} onClick={()=>setCurrentViewId(item?.id)} className={`d-flex justify-content-between py-2 border-bottom pointer ${item?.id === currentViewId ? styles.activeData : styles.data}`}>
              <div>
                <FaCircle color={item?.id === currentViewId ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 155, 192, 1)'} className="shadow" />
                <span className="ms-2">{item?.day}</span>
              </div>
              <p className="m-0">{item?.time}</p>
            </div>
          ))}

          {/* <div className="d-flex justify-content-between py-2 border-bottom">
            <div>
              <FaCircle color="rgba(0, 155, 192, 1)" className="shadow" />
              <span className="ms-2">29/12/2021</span>
            </div>
            <p className="m-0">5:00PM</p>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default PreviousCounts;
