import React, { createContext, useReducer } from 'react';

export const AppContext = createContext();

const ContextProvider = ({children})=>{
    const initialState = {
        jwt:'',
        service_desk_counts: [{}],
        software_counts: [{}],
        scheme_counts: [{}],
        confluence_counts: [{}]
    }

    const reducer = (state,action)=>{
        switch (action.type) {
            case 'UPDATE_JWT':
                return{
                    ...state,
                    jwt: action.payload
                }

            case 'UPDATE_SOFTWARE_DATA':
                return{
                    ...state,
                    software_counts: action.payload
                }

            case 'UPDATE_SERVICEDESK_DATA':
                return{
                    ...state,
                    service_desk_counts: action.payload
                }

            case 'UPDATE_SCHEME_DATA':
                return{
                    ...state,
                    scheme_counts: action.payload
                }

            case 'UPDATE_RESOURCE_DATA':
                return{
                    ...state,
                    confluence_counts: action.payload
                }

            default:
                return state;
        }
    }
    const [ state, dispatch ] = useReducer( reducer, initialState );

    return(
        <AppContext.Provider value={[state,dispatch]}>
            { children }
        </AppContext.Provider>
    )
}

export default ContextProvider;