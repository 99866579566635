import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";
import { titleCase, sortObjectKeys, generateGraphData, formatDate } from "../../utils.ts";
import { AppContext } from "../../store/index";
import "./jira-service-desk-counts.scss";
import Table from "../Table/table";
import PreviousCounts from "../previous-counts/previous-counts";
import BarChartView from "../bar-chart/bar-chart.tsx";

const JiraServiceDeskCounts = (props) => {
  const [state, dispatch] = useContext(AppContext);
  const [serviceDeskCountsItems, setServiceDeskCountsItems] = useState([]);
  const [graphData, setGraphData] = useState({});
  const [previousData, setPreviousData] = useState([]);
  const [currentViewId, setCurrentViewId] = useState(1);
  const sortedList = useMemo(() => sortObjectKeys(state.service_desk_counts[0]?.data), [state?.softwareCountItems]);
  const graphDataLabels = useMemo(() => sortedList.map(row => titleCase(row[0])), [sortedList]);
  const date = useMemo(() => formatDate(state?.service_desk_counts?.filter(info => info?.id == currentViewId)[0]?.date, "LL"), [currentViewId, state.service_desk_counts]);

  useEffect(() => {
    if (state?.service_desk_counts?.length > 0) {
      setPreviousData(state.service_desk_counts.slice(1));

      setGraphData((prev) => ({
        ...prev,
        labels: graphDataLabels,
      }));

      state.service_desk_counts.forEach((item, index) => {
        generateGraphData(setGraphData, sortedList, item, index + 1);
      });
    }
  }, [state.service_desk_counts]);

  useEffect(() => {
    dispatch({ type: null, payload: null });
  }, [dispatch]);

  useEffect(() => {
    updateDataTable();
  }, [currentViewId]);

  const updateDataTable = useCallback(() => {
    const parsedData = state?.service_desk_counts?.filter(info => info?.id === currentViewId);
    if (parsedData?.length > 0) {
      const items = sortedList.map((row, id) => {
        const title = titleCase(row[0]);
        graphDataLabels.push(title);
        return {
          id,
          title,
          description: parsedData[0]?.data[row[0]],
        };
      });
      setServiceDeskCountsItems(items);
    }

  }, [currentViewId, state.service_desk_counts, setServiceDeskCountsItems]);


  return (
    <div className="jira-service-desk-counts-body">
      <div className="mb-5">
        <h1>Jira Service Management</h1>
        <p>{date}</p>
      </div>
      <div
        className="container-fluid text-center mt-3"
      >
        {Object.keys(graphData).length && <BarChartView data={graphData} />}
      </div>
      <div className="mt-5">
        <Table data={serviceDeskCountsItems} />
        {currentViewId > 1 && <div className="d-flex justify-content-end mt-5">
          <button className={`btn btn-primary`} onClick={() => setCurrentViewId(1)}>Reset Table Data</button>
        </div>}
      </div>
      <div className="mt-5">
        <PreviousCounts history={previousData} setCurrentViewId={setCurrentViewId} currentViewId={currentViewId} />
      </div>
    </div>
  );
};

export default JiraServiceDeskCounts;
