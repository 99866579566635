import React, { useState, useEffect, useCallback, useMemo } from "react";
import { SiMicrosoftexcel } from "react-icons/si";
import { FaFilePdf, FaFileWord } from "react-icons/fa";
import { VscJson } from "react-icons/vsc";
import { sortObjectKeys, titleCase, formatDate, exportAsPdfFile, formatDataForExport, generatePreliminaryHeaders } from "../../utils";
import * as XLSX from "xlsx";

const Exports = ({ info }: {info: {data: any, title: string}}) => {
  const [exportData, setExportData] = useState<{[key: string]: string}[]>([]);
  const [excelHeader, setExcelHeader] = useState<string[][]>([]);
  const title = useMemo(()=> { let tit = `${info?.title} Count`; return tit;}, [info]);

  useEffect(() => {
    if (info?.data?.length > 0) {
      let headers = ["Date"];
      const rest = info.data;
      const sortedData = sortObjectKeys(rest[0]?.data);
      sortedData.forEach((row: any[]) => {
        const title = titleCase(row[0]);
        headers.push(title);
      });

      setExcelHeader([headers]);

      let tempExportData: {[key: string]: string}[] = [];

      rest.forEach((prev: {[key: string]: string}) => {
        let holder: {[key: string]: string} = {};
        sortedData.forEach((row: any[]) => {
          holder[row[0]] = prev.data[row[0]];
        });
        tempExportData.push({ date: formatDate(prev.date), ...holder });
      });
      setExportData(tempExportData);
    }
  }, [info]);

  const exportToExcelOrCsv = useCallback((exportAsCsv = false) => {
    if(exportData && exportData?.length){
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(exportData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Products");

    // customize header names
    XLSX.utils.sheet_add_aoa(worksheet, excelHeader);

    XLSX.writeFile(workbook, title + (exportAsCsv ? '.csv' : '.xlsx'), { compression: true, bookType: exportAsCsv ? "csv" : "xlsx" });
    }
  }, [exportData, excelHeader, title]);
  
  // const exportToPdf = useCallback(() => {
  //   exportAsPdfFile(formatDataForExport(exportData), title + '.pdf', []);
  // }, [exportData, excelHeader, title]);

  const exportToPdf = useCallback(() => {
    if(info?.data){
      exportAsPdfFile(formatDataForExport(info?.data), title, generatePreliminaryHeaders(info?.data), 'landscape');
    }
  }, [info, title]);



  return (
    <>
      <div className="container-fluid m-0">
        <div className="row px-3 m-0 justify-content-around justify-content-md-between border-start border-bottom border-dark border-4 gap-3 py-4">
          <div className="col-5 d-flex p-0 justify-content-center align-items-center" onClick={() => exportToExcelOrCsv()}>
            <SiMicrosoftexcel size={"3em"} color="rgba(0, 155, 192, 1)" />
          </div>
          <div className="col-5 d-flex p-0 justify-content-center align-items-center" onClick={exportToPdf}>
            <FaFilePdf size={"3em"} color="rgba(0, 155, 192, 1)" />
          </div>
          <div className="col-5 d-flex p-0 justify-content-center align-items-center">
            <VscJson size={"3em"} color="rgba(0, 155, 192, 1)" onClick={() => exportToExcelOrCsv(true)} />
          </div>
          {/* <div className="col-5 d-flex p-0 justify-content-center align-items-center">
            <FaFileWord size={"3em"} color="rgba(0, 155, 192, 1)" />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Exports;
