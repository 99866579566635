import React, { useContext, useState, useEffect } from "react";
import Axios from "axios";
import { AppContext } from "../../store/index";
import "./attachments.scss";

const Attachments = () => {
  const [state, dispatch] = useContext(AppContext);
  const [responseData, setResponseData] = useState({});
  const [loading, isLoading] = useState(false);
  const [viewBtn, showViewBtn] = useState(true);
  const [output, showOutput] = useState(false);

  const attachmentRequest = () => {
    showViewBtn(false);
    isLoading(true);
    Axios.post("/v1/jira/attachment/", { token: state.jwt }).then((res) => {
      const taskId = res.data.task_id;
      const attSocket = new WebSocket(
        "wss://api.jiracounter.net/jira/attachment/" + taskId + "/"
      );
      attSocket.onmessage = (e) => {
        const data = JSON.parse(e.data);
        console.log(data);
        const attachmentData = JSON.parse(data.message);
        setResponseData(attachmentData.attachments);
        isLoading(false);
        showOutput(true);
      };
    });
  };

  useEffect(() => {
    dispatch({ type: null, payload: null });
  }, [dispatch]);

  return (
    <div className="attachments">
      <button
        className={`view-attachments-btn ${viewBtn === false ? "hide" : ""}`}
        onClick={attachmentRequest}
      >
        View Attachments
      </button>
      <div className={`loading ${loading === false ? "hide" : "show"}`}>
        <img src={require("../../assets/loading_loop.gif")} alt="loader" />
      </div>
      <div
        className={`attachments-output ${output === true ? "show" : "hide"}`}
      >
        <div className="output-row">
          <p className="title">Attachments</p>
          <table className="attachment-table">
            <thead>
              <tr>
                <th>Count</th>
                <th>Size</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{responseData.count}</td>
                <td>{responseData.total_size}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Attachments;
