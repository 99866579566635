import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: false,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    // title: {
    //   display: true,
    //   text: 'Chart.js Bar Chart',
    // },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const dataTest = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [300, 150, 600, 50, 200, 300, 350, 600, 50, 200, 300, 350],
      backgroundColor: 'rgb(255, 99, 132)',
      stack: 'Stack 0',
    },
    {
      label: 'Dataset 2',
      data: [300, 150, 600, 50, 200, 300, 350, 600, 50, 200, 300, 350],
      backgroundColor: 'rgb(75, 192, 192)',
      stack: 'Stack 2',
    },
    {
      label: 'Dataset 3',
      data: [300, 150, 600, 50, 200, 300, 350, 600, 50, 200, 300, 350],
      backgroundColor: 'rgb(53, 162, 235)',
      stack: 'Stack 1',
    },
  ],
};

// const testData = [
//   {
//     name: "Page A",
//     uv: 4000,
//     pv: 2400,
//     amt: 2400
//   },
//   {
//     name: "Page B",
//     uv: 3000,
//     pv: 1398,
//     amt: 2210
//   },
//   {
//     name: "Page C",
//     uv: 2000,
//     pv: 9800,
//     amt: 2290
//   },
//   {
//     name: "Page D",
//     uv: 2780,
//     pv: 3908,
//     amt: 2000
//   },
//   {
//     name: "Page E",
//     uv: 1890,
//     pv: 4800,
//     amt: 2181
//   },
//   {
//     name: "Page F",
//     uv: 2390,
//     pv: 3800,
//     amt: 2500
//   },
//   {
//     name: "Page G",
//     uv: 3490,
//     pv: 4300,
//     amt: 2100
//   }
// ];

const BarChartView = ({ data }: {data: ChartData<"bar", number[], unknown>}) => {
  return <>
    <div style={{width: '100%', overflowX: 'scroll'}}>
      <Bar height={'400px'} width={'1200px'} options={options} data={data} />
    </div>
  </>;
}

export default BarChartView;
