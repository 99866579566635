import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";
import { AppContext } from "../../store/index";
import {
  titleCase,
  sortObjectKeys,
  generateGraphData,
  formatDate,
} from "../../utils.ts";
import "./jira-software-counts.scss";
import Table from "../Table/table";
import PreviousCounts from "../previous-counts/previous-counts";
import BarChartView from "../bar-chart/bar-chart.tsx";

const JiraSoftwareCounts = (props) => {
  const [state, dispatch] = useContext(AppContext);
  const [softwareCountItems, setSoftwareCountItems] = useState([]);
  const [graphData, setGraphData] = useState({});
  const [previousData, setPreviousData] = useState([]);
  const [currentViewId, setCurrentViewId] = useState(1);
  const sortedList = useMemo(() => sortObjectKeys(state.software_counts[0]?.data), [state?.softwareCountItems]);
  const graphDataLabels = useMemo(() => sortedList.map(row => titleCase(row[0])), [sortedList]);
  const date = useMemo(() => formatDate(state?.software_counts?.filter(info => info?.id == currentViewId)[0]?.date, "LL"), [currentViewId, state.software_counts]);

  // const testResponse = {
  //   "dashboards": 16,
  //   "project roles": 114,
  //   "projects": 118,
  //   "workflows": 227,
  //   "issue fields": 406,
  //   "issue types": 186,
  //   "workflow statuses": 163,
  //   "issue priorities": 5,
  //   "issue resolutions": 8,
  //   "issue link types": 18,
  //   "groups": 31,
  //   "screens": 571,
  //   "custom fields": 362,
  //   "issues": 7416,
  //   "previous": [
  //       {
  //           "data": {
  //               "dashboards": 16,
  //               "project roles": 114,
  //               "projects": 118,
  //               "workflows": 227,
  //               "issue fields": 406,
  //               "issue types": 186,
  //               "workflow statuses": 163,
  //               "issue priorities": 5,
  //               "issue resolutions": 8,
  //               "issue link types": 18,
  //               "groups": 31,
  //               "screens": 571,
  //               "custom fields": 362,
  //               "issues": 7416
  //           },
  //           "date": "2023-02-23T04:44:06.189444Z"
  //       },
  //       {
  //           "data": {
  //               "dashboards": 16,
  //               "project roles": 114,
  //               "projects": 118,
  //               "workflows": 227,
  //               "issue fields": 406,
  //               "issue types": 186,
  //               "workflow statuses": 163,
  //               "issue priorities": 5,
  //               "issue resolutions": 8,
  //               "issue link types": 18,
  //               "groups": 31,
  //               "screens": 571,
  //               "custom fields": 362,
  //               "issues": 7416
  //           },
  //           "date": "2023-02-23T04:38:35.685553Z"
  //       },
  //       {
  //           "data": {
  //               "dashboards": 16,
  //               "project roles": 114,
  //               "projects": 118,
  //               "workflows": 227,
  //               "issue fields": 406,
  //               "issue types": 186,
  //               "workflow statuses": 163,
  //               "issue priorities": 5,
  //               "issue resolutions": 8,
  //               "issue link types": 18,
  //               "groups": 31,
  //               "screens": 571,
  //               "custom fields": 362,
  //               "issues": 7416
  //           },
  //           "date": "2023-02-23T04:38:07.099073Z"
  //       }
  //   ]
  // };

  // const graphData = {
  //   labels: [],
  //   datasets: [],
  // };

  useEffect(() => {
    if (state?.software_counts?.length > 0) {
      setPreviousData(state.software_counts.slice(1));

      setGraphData((prev) => ({
        ...prev,
        labels: graphDataLabels,
      }));

      state.software_counts.forEach((item, index) => {
        generateGraphData(setGraphData, sortedList, item, index + 1);
      });
    }
  }, [state.software_counts]);

  useEffect(() => {
    dispatch({ type: null, payload: null });
  }, [dispatch]);

  useEffect(() => {
    updateDataTable();
  }, [currentViewId]);

  const updateDataTable = useCallback(() => {
    const parsedData = state?.software_counts?.filter(info => info?.id === currentViewId);
    if (parsedData?.length > 0) {
      const items = sortedList.map((row, id) => {
        const title = titleCase(row[0]);
        graphDataLabels.push(title);
        return {
          id,
          title,
          description: parsedData[0]?.data[row[0]],
        };
      });
      setSoftwareCountItems(items);
    }

  }, [currentViewId, state.software_counts, setSoftwareCountItems]);

  return (
    <div className="jira-software-counts-body">
      <div className="mb-5">
        <h1>Jira Software</h1>
        <p>{date}</p>
      </div>
      <div className="container-fluid text-center mt-3">
        {Object.keys(graphData).length && <BarChartView data={graphData} />}
      </div>
      <div className="mt-5 w-100">
        <Table data={softwareCountItems} />
        {currentViewId > 1 && <div className="d-flex justify-content-end mt-5">
          <button className={`btn btn-primary`} onClick={() => setCurrentViewId(1)}>Reset Table Data</button>
        </div>}
      </div>
      <div className="mt-5">
        <PreviousCounts history={previousData} setCurrentViewId={setCurrentViewId} currentViewId={currentViewId} />
      </div>
    </div>
  );
};

export default JiraSoftwareCounts;
