import React from "react";
import styles from './table.module.scss';

const Table = ({ data }) => {
    return (
        <>
            <div className={`container-fluid m-0 p-0 ${styles.table}`}>
                <table className="table table-borderless">
                    <thead className="border-bottom">
                        <tr>
                            <th scope="col" className="fw-bolder">#</th>
                            <th scope="col" className="fw-bolder">Resource</th>
                            <th scope="col" className="fw-bolder">Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map(softwareCount => (
                                <tr key={softwareCount.title}>
                                    <td>{+softwareCount.id+1}</td>
                                    <td>{softwareCount.title}</td>
                                    <td>{softwareCount.description}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Table;